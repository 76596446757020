import React from "react";
import { Navbar } from "./navbar/navbar";
import "./landing.css";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const Landingpage = () => {
	const navigate = useNavigate();

	return (
		<div className="landingpage">
			<Navbar></Navbar>
			<div className="landingcontents">
				<span className="landh1">TNEA Grievance Portal 2022</span>
				<span className="landcontent">
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita
					assumenda itaque facere totam beatae vel a adipisci animi quis velit,
					molestiae facilis, harum optio ducimus minus? Fugiat commodi ea
					quibusdam! Lorem ipsum dolor sit amet consectetur, adipisicing elit.
					Ex nostrum nisi temporibus, provident nemo officiis quasi aperiam
					ipsum esse quibusdam optio, iure dolorum natus harum eligendi mollitia
					inventore unde ipsam.
				</span>
				<span className="landcontent2">
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Tenetur
					similique explicabo, impedit adipisci iusto possimus neque earum,
					necessitatibus quae sit mollitia, enim exercitationem. Voluptas
					excepturi nobis reprehenderit incidunt atque magnam!
				</span>
				<div>
					<button
						style={{ backgroundColor: "#d9d9d9", color: "Black" }}
						onClick={() => {
							navigate("/tfcdata");
						}}
					>
						TFC List
					</button>
					<button
						onClick={() => {
							navigate("/login");
						}}
					>
						Login
					</button>
				</div>
				{/* <button style={{width:"50px"}}>Login</button> */}
			</div>
		</div>
	);
};
