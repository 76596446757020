import React from "react";
import tfcdata from "./tfcdata.json";
import { Navbar } from "./navbar/navbar";
import { Space, Table, Tag } from "antd";
import './tfcdata.css'
// import type { ColumnsType } from 'antd/lib/table';

const columns = [
	{
		title: "District",
		dataIndex: "district",
		key: "district"
	},
	{
		title: "TFC No",
		dataIndex: "tfcno",
		key: "tfcno"
	},
	{
		title: "TFC Name",
		dataIndex: "tfcname",
		key: "tfcname"
	},
	{
		title: "Coordinator",
		dataIndex: "coordinator",
		key: "coordinator"
	},
	{
		title: "Mobile No",
		dataIndex: "mobileno",
		key: "mobileno"
	}
];

const Tfcdata = () => {
	return (
		<div className="tablepage">
			<Navbar />
			<div className="tablecontainer">
				<Table dataSource={tfcdata} columns={columns} />;
			</div>
		</div>
	);
};

export default Tfcdata;
